import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Air Freight Forwarding
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Air Freight Forwarding
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div className="p-4 ">
                  <img
                    src="assets/images/Air Freight Forwarding.jpg"
                    className="img-fluid radius-image"
                    alt="blog-post-image"
                  />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                    Air Freight Forwarding
                  </h3>
                  <p>
                    In every significant trade channel, {companyname} leverages
                    strategic partnerships with major airline conglomerates to
                    offer you competitive rates, swift transit times, stable
                    allocations, and reliable carrier capacity. Our commitment
                    to air freight solutions, dedicated account managers, and
                    round-the-clock service assurance ensures we deliver
                    unparalleled synergies to boost the success of your
                    business.
                  </p>

                  <p>Some of the key features of our services include:</p>
                  <ul>
                    <li>
                      <p>Cargo pick-up from the consignee's warehouse.</p>
                    </li>
                    <li>
                      <p>Expediting and vendor management services.</p>
                    </li>
                    <li>
                      <p>Import clearance of cargo at the port of discharge.</p>
                    </li>
                    <li>
                      <p>In-house export packing services.</p>
                    </li>
                    <li>
                      <p>Comprehensive documentation support.</p>
                    </li>
                    <li>
                      <p>
                        Safe handling of both general cargo and perishable
                        cargo.
                      </p>
                    </li>
                    <li>
                      <p>Door-to-Door Express Products.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
