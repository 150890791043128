import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Contact Us
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Contact
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* //inner banner */}
      <div
        style={{ margin: "8px auto", display: "block", textAlign: "center" }}
      >
        {/*-728x90-*/}
      </div>
      {/* contact section */}
      <section className="w3l-contact-2 py-5" id="contact">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="w3l-header-sec text-center mb-md-5 mb-4">
            <h5 className="sub-title">Send Us Message</h5>
            <h3 className="title-style">Contact Us</h3>
          </div>
          <div className="contact-grids d-grid">
            <div className="contact-left-img">
              <img
                src="assets/images/contact.jpg"
                className="img-fluid radius-image"
                alt=""
              />
            </div>
            <div className="contact-right">
              <form
                id="contactForm"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                    <input
                      className="form-control"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                    <input
                      className="form-control"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                    <input
                      className="form-control"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                    <input
                      className="form-control"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                  </div>
                  <div className="col-lg-12 mb-30">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                  </div>

                  <div className="btn-part">
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-style btn-primary"
                        type="submit"
                        defaultValue=""
                      >
                        Send A Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
          <div className="contact-left mt-5 pt-lg-5">
            <div className="w3l-header-sec text-center mb-md-5 mb-4">
              <h5 className="sub-title">Find Us</h5>
              <h3 className="title-style">Contact Details</h3>
            </div>
            <div className="cont-details">
              <div className="cont-top margin-up">
                <div className="cont-left text-center">
                  <i className="fas fa-map-marked-alt" />
                </div>
                <div className="cont-right">
                  <h6>Company Address</h6>
                  <p>{companyaddress}</p>
                </div>
              </div>
              <div className="cont-top margin-up">
                <div className="cont-left text-center">
                  <i className="fas fa-phone" />
                </div>
                <div className="cont-right">
                  <h6>Call Us</h6>
                  <p>
                    {companynumber}
                  </p>
                 
                </div>
              </div>
              <div className="cont-top margin-up">
                <div className="cont-left text-center">
                  <i className="fas fa-mail-bulk" />
                </div>
                <div className="cont-right">
                  <h6>Email Us</h6>
                  <p>
                    {companyemail}
                  </p>
                  
                </div>
              </div>
              <div className="cont-top margin-up">
                <div className="cont-left text-center">
                  <i className="fas fa-globe" />
                </div>
                <div className="cont-right">
                  <h6>Website</h6>
                  <p>
                  {companyurl}
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="map-iframe mt-5 pt-md-4">
            <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7541.423599951685!2d72.99813983692091!3d19.076404860289053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1385adaa917%3A0x36d603953c5c0ec3!2sAPMC%20Market%2C%20Sector%2019%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!5e0!3m2!1sen!2sin!4v1693825891610!5m2!1sen!2sin" 
              width="100%"
              height={400}
              frameBorder={0}
              style={{ border: "0px" }}
              allowFullScreen
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
