import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Sea Freight Forwarding
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Sea Freight Forwarding
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div className="p-4 ">
                  <img
                    src="assets/images/Sea Freight Forwarding.jpg"
                    className="img-fluid radius-image"
                    alt="blog-post-image"
                  />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                    Sea Freight Forwarding
                  </h3>
                  <p>
                    {companyname} specializes in handling a wide range of cargo,
                    including sensitive goods, unusual dimensions, and shipments
                    to distant locations.
                  </p>

                  <p>
                    Our state-of-the-art logistics solutions serve as a gateway
                    to your sales and procurement markets, no matter where your
                    shipments need to reach. Through our partnerships with
                    multiple carriers, we provide a unified logistics platform
                    for all your supply chain needs.
                  </p>

                  <p>
                    We offer a comprehensive suite of services, including
                    flexible and reliable quote requests, access to sailing
                    schedules, efficient purchase order management, and space
                    allocation during peak demand periods. With our tailored
                    solutions, you can confidently navigate the complexities of
                    the shipping industry.
                  </p>

                  <p>Our customized services encompass:</p>
                  <ul>
                    <li>
                      <p>FCL and LCL consolidation.</p>
                    </li>
                    <li>
                      <p>Multimodal transportation by sea, air, and road.</p>
                    </li>
                    <li>
                      <p>Custom clearance and brokerage services.</p>
                    </li>
                    <li>
                      <p>Cargo insurance services.</p>
                    </li>
                    <li>
                      <p>
                        Pick-up, packing options, delivery, and distribution.
                      </p>
                    </li>
                    <li>
                      <p>Complete documentation service.</p>
                    </li>
                    <li>
                      <p>
                        Sensitive freight handling, including Dry, Liquid, Bulk,
                        and Perishables.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
