import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <section className="w3l-main-slider" id="home">
        <div
          className="banner-content w3-banner jarallax"
          style={{ background: "black" }}
        >
          <video
            className="img-fluid"
            autoPlay
            muted
            loop
            style={{ zIndex: "1" }}
          >
            <source src="assets/images/video.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
      {/* //banner section */}
      {/* about section */}
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h3 className="title-style">Welcome to {companyname}</h3>
              <p className="mt-3">
                Starting from humble beginnings, we've evolved into a leading
                market player and the preferred logistics partner across various
                sectors. Our expertise extends to efficiently managing both
                incoming and outgoing shipments. Headquartered in Mumbai, our
                central office serves as a one-stop solution hub for our
                clients, enabling them to focus on their core operations. We
                specialize in delivering a hassle-free range of services
                tailored to our clients' needs.
              </p>
              <div className="row mt-lg-5 mt-4">
                <div className="col-sm-6 grids_info">
                  <i className="fas fa-bullseye" />
                  <div className="detail mt-sm-4 mt-3">
                    <h4>Our Mission</h4>
                    <p>
                      Our commitment is unwavering as we endeavor to uphold our
                      promises and deliver exceptional value-added services to
                      our clients. Our aspiration is to set new industry
                      standards in terms of quality, reliability, and
                      consistency.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 grids_info mt-sm-0 mt-4">
                  <i className="fas fa-eye" />
                  <div className="detail mt-sm-4 mt-3">
                    <h4> Our Vision</h4>
                    <p>
                      Our vision is to establish ourselves as a renowned
                      logistics company, fortified by a robust agent network and
                      a loyal clientele. We aim to enhance logistics efficiency
                      and become the premier omnichannel service provider.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/about.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //about section */}
      {/* courses section */}
      <section className="w3l-courses py-5" id="courses">
        <div className="container pb-lg-5 pb-md-4 pb-2">
          <h5 className="sub-title text-center">
            Our Freight Forwarding Services
          </h5>
          <h3 className="title-style text-center">Services</h3>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Sea" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Sea Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Sea">
                      {" "}
                      <p>Sea Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc"><p> {companyname} specializes in handling a wide range of cargo,
                    including sensitive goods, unusual dimensions, and shipments
                    to distant locations.</p></Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Air" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Air Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Air">
                      {" "}
                      <p>Air Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc"><p> In every significant trade channel, {companyname} leverages
                    strategic partnerships with major airline conglomerates to
                    offer you competitive rates, swift transit times, stable
                    allocations, and reliable carrier capacity.</p></Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Surface" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Surface Transportation.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Surface">
                      {" "}
                      <p>Surface Transportation</p>
                    </Link>
                  </div>
                  <Link className="course-desc"><p> Our land transport services are meticulously tailored to
                    meet the precise requirements of our clients. We provide you
                    with the assurance of dependable budgeting and efficient
                    goods transfer by clearly defining transit timeframes.</p></Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="mt-5 mx-auto text-more text-center pt-lg-4">
              <Link to="/Services" className="btn btn-style">
                View All Services
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* //courses section */}
      {/* why section */}
      {/* //why section */}
      {/* team section */}
      <div className="w3l-team-grids-sec py-5" id="team">
        <div className="container pb-lg-5 pb-md-4 pb-2">
          <h5 className="sub-title text-center">Trading Solutions</h5>
          <h3 className="title-style text-center">
            {" "}
            Trading Solutions We Provide
          </h3>
          <div className="row cards justify-content-center">
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Healthcare.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Healthcare</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Retail.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Retail</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Construction.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Construction</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Consumer Electronics.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Consumer Electronics</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Direct to Consumer.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Direct to Consumer</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Marketplace & Sellers.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Marketplace & Sellers</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* //team section */}
      {/* content block1 */}
      <section className="w3l-content-block1 py-5">
        <div className="container py-md-5 py-4">
          <div className="row">
            <div className="col-lg-6 col-md-8 title-content py-md-5">
              <h3 className="title-style text-white">
                Navigating Challenges, Delivering Success.
              </h3>
              <p className="mt-3 text-light">
                Ready to streamline your logistics? Complete our quick 'Get a
                Quote' form, and we'll provide you with competitive shipping
                rates in no time.
              </p>
              <Link to="/Getquote" className="btn btn-style mt-5">
                Request A Quote
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* //content block1 */}
      {/* blog section */}
      <section className="w3l-blog py-5" id="blog">
        <div className="container py-lg-5 py-md-4 py-2">
          <h5 className="sub-title text-center">Services</h5>
          <h3 className="title-style text-center">Our Other Services</h3>
          <div className="row inner-sec-w3ls justify-content-center">
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Warehousing" className="d-block">
                    <img
                      src="assets/images/Warehousing & Distribution.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Multimodal" className="d-block">
                    <img
                      src="assets/images/Multimodal Transportation.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Projects" className="d-block">
                    <img
                      src="assets/images/Projects & Break Bulk.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Projects">Projects & Break Bulk</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Customs" className="d-block">
                    <img
                      src="assets/images/Customs Clearance.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Customs">Customs Clearance</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Hazardous" className="d-block">
                    <img
                      src="assets/images/Hazardous Cargo.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //blog section */}
      {/* instagram-feeds */}
      <Footer />
    </>
  );
}
