import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Trading Solutions
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Trading Solutions
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Page Title End */}
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h3 className="title-style">Healthcare</h3>
              <p className="mt-3">
                <p>
                  Our unique blend of specialized healthcare logistics resources
                  positions us as an ideal partner for any healthcare
                  organization. We offer:
                </p>

                <ul>
                  <li>
                    <p>
                      Dedicated compliance-based program management and site
                      audits
                    </p>
                  </li>
                  <li>
                    <p>In-house developed real-time technology</p>
                  </li>
                  <li>
                    <p>Highly trained staff and state-of-the-art facilities</p>
                  </li>
                  <li>
                    <p>
                      Regional warehouse infrastructure and transport network
                    </p>
                  </li>
                  <li>
                    <p>
                      Flexible capacity for surface, air, ocean, and multi-modal
                      transport
                    </p>
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Healthcare.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Retail.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <h3 className="title-style">Retail</h3>
              <p className="mt-3">
                <p>
                  Many retailers initially extended their e-commerce supply
                  chain logistics program as an outgrowth of their existing
                  retail channels. However, an increasing number of merchants
                  are realizing that online retailing demands dedicated and
                  sophisticated solutions. We are here to assist you in
                  establishing and deploying comprehensive turnkey e-commerce
                  supply chain operations, replacing your legacy and standalone
                  systems and processes. This transformation not only boosts
                  revenues but also lowers costs.
                </p>

                <p>
                  Navigating the intricacies of your supply chain to meet the
                  ever-growing demands of the consumer marketplace can be a
                  daunting task. It involves various elements, including
                  websites, optimization, warehouses, returns, and small parcel
                  freight carriers, to name just a few. The {companyname} eCommerce
                  solution offers a comprehensive range of services,
                  encompassing website development and maintenance, warehouse
                  logistics management, transportation management, routing
                  instructions, and dynamic reporting. It's an end-to-end supply
                  chain solution for your web-based orders, allowing you to
                  effectively manage your inventory and your website.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h3 className="title-style">Construction</h3>
              <p className="mt-3">
                <p>
                  Construction logistics are rarely straightforward, and at Good
                  Logistics, we understand the necessity of meticulous attention
                  to every detail in your complex construction process. Ensuring
                  the timely arrival of building materials is an absolute
                  imperative.
                </p>

                <p>
                  When it comes to construction projects, reliability is just as
                  crucial as cost-effectiveness to avoid costly downtime. Our
                  freight forwarders have cultivated strong partnerships with
                  dependable global counterparts. We are dedicated to finding
                  the optimal balance between price and reliability that aligns
                  perfectly with your project schedule. Moreover, our
                  experienced professionals in project cargo and out-of-gauge
                  shipments are equipped to handle even the most substantial
                  challenges.
                </p>
              </p>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Construction.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Consumer Electronics.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <h3 className="title-style">Consumer Electronics</h3>
              <p className="mt-3">
                <p>
                  We possess a profound understanding of your business and a
                  deep comprehension of your customers' demands, enabling us to
                  deliver consumer electronic logistics solutions that surpass
                  your expectations.
                </p>

                <p>
                  Through our comprehensive end-to-end, one-stop service, we
                  meticulously oversee the "time to market" process, ensuring
                  the fulfillment of your commitments to end customers within a
                  framework of unwavering security and real-time visibility.
                </p>

                <p>
                  Our specialized services tailored to Consumer Electronics
                  Solutions encompass a diverse array of offerings. From global
                  freight management to warehousing and order fulfillment, our
                  solutions cover every facet of the consumer electronics
                  industry, ensuring a seamless and efficient supply chain.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h3 className="title-style">Direct to Consumer</h3>
              <p className="mt-3">
                <p>
                  The surge of e-commerce has revolutionized the retail
                  industry, shifting the balance of power from retailers to
                  consumers. Traditionally, brands that relied on other
                  retailers found themselves in the middle between the retailer
                  and the consumer. However, the current landscape presents them
                  with a unique opportunity to redefine their operations.
                </p>

                <p>
                  The brands that are thriving in this evolving landscape are
                  those that have recognized and embraced this change. The focus
                  now is on direct-to-consumer sales, whether through the
                  establishment of their own stores and showrooms, the creation
                  of their websites, or participation in online marketplaces.
                  This transformation is eliminating the need for intermediaries
                  and empowering brands to establish direct connections with
                  their customer base.
                </p>
              </p>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Direct to Consumer.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/Marketplace & Sellers.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <h3 className="title-style">Marketplace & Sellers</h3>
              <p className="mt-3">
                <p>
                  As buying habits and selling processes undergo
                  transformations, it becomes crucial that the service providers
                  supporting these processes stay updated with the latest trends
                  and can adapt their own business models accordingly.
                  Marketplaces predominantly thrive on the contributions of
                  numerous independent sellers, including artisans, craft
                  vendors, hobbyists, and boutique merchants. We firmly believe
                  that they should have the opportunity to harness the
                  advantages of collectively shipping large volumes.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
