import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Careers() {
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
  const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  };
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  };
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
            Careers
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Careers
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* //inner banner */}
      <div
        style={{ margin: "8px auto", display: "block", textAlign: "center" }}
      >
        {/*-728x90-*/}
      </div>
      {/* contact section */}
      <section className="w3l-contact-2 py-5" id="contact">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="w3l-header-sec text-center mb-md-5 mb-4">
            <h5 className="sub-title">Careers</h5>
            <h3 className="title-style">Careers At {companyname}</h3>
            <h4>Ready to be a part of something bigger? Filling out this form not only opens doors for your career but also contributes to our continued growth and success.</h4>
          </div>
          <div className="contact-grids d-grid" style={{gridTemplateColumns:"2fr"}}>
           
            <div className="contact-right">
            <form
                  action="/php/thankyou-careers.php"
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row p-2">
                    <div className="col-md-4">
                      <div className="comment-form__input-box">
                        <input
                          onChange={(event) => handlefullnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={fullname}
                          id="fullname"
                          name="fullname"
                          placeholder="Your Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comment-form__input-box">
                        <input
                          onChange={(event) => handleemailidChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={emailid}
                          id="emailid"
                          name="emailid"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comment-form__input-box">
                        <input
                          onChange={(event) => handlephoneChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={phone}
                          id="handlephoneChange"
                          name="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <label>Highest Qualification</label>
                  <div className="col-12 p-2">
                    <div className="comment-form__input-box">
                      <input
                        onChange={(event) => handlequalificationChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={qualification}
                        id="qualification"
                        name="qualification"
                        placeholder="Qualification"
                        required
                      />
                    </div>
                  </div>
                  <label>Position Applied for</label>
                  <div className="col-12 p-2">
                    <div className="comment-form__input-box">
                      <input
                        onChange={(event) => handlepositionChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={position}
                        id="position"
                        name="position"
                        placeholder="position"
                        required
                      />
                    </div>
                  </div>
                  <label>Add cover letter</label>
                  <div className="col-12 p-2">
                    <div className="comment-form__input-box">
                      <textarea
                        onChange={(event) => handlecoverletterChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        placeholder="Leave a message here"
                        value={coverletter}
                        id="coverletter"
                        name="coverletter"
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="submitcareers"
                      defaultValue="SEND"
                      className="btn btn-style btn-primary"
                    >
                      Apply
                    </button>
                  </div>
                  <br />
                </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
