import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
<footer className="footer footer-classic bg-navy">
<footer className="w3l-footer-16">
        <div className="footer-top-16 py-5">
          <div className="container pt-lg-5 pt-md-4 pt-2 pb-lg-4 pb-2">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <h3>About Us</h3>
                <p>Starting from humble beginnings, we've evolved into a leading market player and the preferred logistics partner across various sectors. Our expertise extends to efficiently managing both incoming and outgoing shipments. Headquartered in Mumbai, our central office serves as a one-stop solution hub for our clients, enabling them to focus on their core operations.</p>
               
              </div>
              <div className="col-lg-2 col-6 column ps-xl-5 mt-sm-0 mt-4">
                <h3>Quick Link</h3>
                <ul className="footer-gd-16">
                  <li><Link to="/Home">Home</Link></li>
                  <li><Link to="/About">About Us</Link></li>
                  <li><Link to="/Services">Services</Link></li>
                  <li><Link to="/Trading">Trading</Link></li>
                  <li><Link to="/Contact">Contact</Link></li>
                  <li><Link to="/Getquote">Request Quote</Link></li>
                  <li><Link to="/Privacy">Privacy Policy</Link></li>
                  <li><Link to="/Terms">Terms</Link></li>


                </ul>
              </div>
              <div className="col-lg-3 col-6 column ps-xl-5 mt-sm-0 mt-4">
                <h3>Services</h3>
                <ul className="footer-gd-16">
                <li>
                      <Link  to="/Sea">
                      Sea Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Air">
                      Air Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Surface">
                      Surface Transportation
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Warehousing">
                      Warehousing & Distribution
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Multimodal">
                      Multimodal Transportation
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Projects">
                      Projects & Break Bulk
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Customs">
                      Customs Clearance
                      </Link>
                    </li>
                    <li>
                      <Link  to="/Hazardous">
                      Hazardous Cargo
                      </Link>
                    </li>


                </ul>
              </div>
              <div className="col-lg-3 col-6 column ps-xl-5 pe-lg-0 mt-lg-0 mt-4">
                <h3>Contact Info</h3>
                <ul className="footer-contact-list">
                  <li className><i className="fas fa-map-marker-alt" /><span>{companyaddress}</span></li>
                  <li className="mt-2"><i className="fas fa-phone-alt" /><span><Link to="tel:{companynumber}">{companynumber}</Link></span></li>
                  <li className="mt-2"><i className="fas fa-mail-bulk" /><span>{companyemail}</span></li>
                  <li className="mt-2"><i className="fas fa-globe" /><span>{companyurl}</span></li>
                </ul>
               
              </div>
             
            </div>
          </div>
        </div>
        <div className="copy-section text-center py-4">
          <p className="copy-text py-1">© 2023  <Link to="/Home" target="_blank"> {companyname}</Link>. All rights reserved. 
          </p>
        </div>
      </footer>
      </footer>
    </>
  );
};

export default Footer;
