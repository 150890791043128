import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Projects & Break Bulk
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Projects & Break Bulk
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div className="p-4 ">
                  <img
                    src="assets/images/Projects & Break Bulk.jpg"
                    className="img-fluid radius-image"
                    alt="blog-post-image"
                  />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                    Projects & Break Bulk
                  </h3>
                  <p>
                    {companyname} excels in the meticulous management of project
                    cargo, a task that demands exceptional skill, care, and
                    attention. Our expertise spans across various industries,
                    including those utilizing heavy machinery, oil and gas,
                    construction, energy, maritime, mining, and engines for both
                    shoreside and maritime applications. We have successfully
                    executed projects in challenging, inaccessible locations
                    with specialized solutions and an unwavering commitment to
                    facilitate smooth operations.
                  </p>

                  <p>
                    Whether your cargo is classified as Break Bulk (BB), Heavy
                    Lift (HL), or Out of Gauge (OOG), we possess the capability
                    to expertly handle complex transportation operations. Choose{" "}
                    {companyname} as your trusted partner to oversee every
                    aspect of your projects from inception to completion. Let us
                    help you extend the horizons of your business goals and turn
                    your plans into reality with our steadfast commitment and
                    expertise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
