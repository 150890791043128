import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Customs Clearance
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Customs Clearance
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div className="p-4 ">
                  <img
                    src="assets/images/Customs Clearance.jpg"
                    className="img-fluid radius-image"
                    alt="blog-post-image"
                  />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">Customs Clearance</h3>
                  <p>
                    Our local knowledge and strong connections with key carriers
                    significantly extend our reach, ensuring that your shipments
                    always reach their destination without encountering issues
                    or fines.
                  </p>

                  <p>
                    We understand the intricacies of local laws, procedures, and
                    the challenges faced by multinational corporations. With
                    this knowledge, we can tailor custom solutions to help you
                    make informed decisions, reduce lead times, optimize cash
                    flow, and minimize tax liabilities.
                  </p>

                  <p>
                    For seamless shipments, entrust your cargo to our customs
                    clearance specialists to avoid legal complications and
                    chaos.
                  </p>

                  <p>Key features of our customs clearance services include:</p>
                  <ul>
                    <li>
                      <p>Customs Documentation.</p>
                    </li>
                    <li>
                      <p>Carting and Receiving Goods.</p>
                    </li>
                    <li>
                      <p>Inspection of Shipments.</p>
                    </li>
                    <li>
                      <p>
                        Handling of stuffing and de-stuffing at Ports, ICDs, and
                        Customers' warehouses.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
