import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo"; 

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
            Warehousing & Distribution
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Warehousing & Distribution
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div  className="p-4 ">
                  <img src="assets/images/Warehousing & Distribution.jpg" className="img-fluid radius-image" alt="blog-post-image" />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                  Warehousing & Distribution</h3>
                  <p>{companyname} offers comprehensive warehousing solutions, backed by a proficient workforce and exceptional managerial services.</p>

<p>Our robust infrastructure, designed to align seamlessly with your company's scalable supply chain, is characterized by our transparent and responsive procedures and services. Elevate your business strategy with our Third-Party Logistics (3PL) services, encompassing state-of-the-art warehousing facilities, skilled labor, and proficient supply chain management.</p>

<p>Contract Logistics with us includes:</p>
<ul>
  <li><p>End-to-end surveillance.</p></li>
  <li><p>Material handling (Standard & Non-standard cargo, ODC Shipments, Perishables, Volatile goods).</p></li>
  <li><p>Asset-light model.</p></li>
  <li><p>Customized storage solutions.</p></li>
  <li><p>Flexible space and transportation.</p></li>
  <li><p>MHE / HPT / Forklift / Reach Trucks.</p></li>
</ul>

                </div>
                
               
               
              </div>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
