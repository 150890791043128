import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
            About Us
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                About Us
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Page Title End */}
      <section className="w3l-aboutblock py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h3 className="title-style">Welcome to {companyname}</h3>
              <p className="mt-3">
                Starting from humble beginnings, we've evolved into a leading
                market player and the preferred logistics partner across various
                sectors. Our expertise extends to efficiently managing both
                incoming and outgoing shipments. Headquartered in Mumbai, our
                central office serves as a one-stop solution hub for our
                clients, enabling them to focus on their core operations. We
                specialize in delivering a hassle-free range of services
                tailored to our clients' needs.
              </p>
              <div className="row mt-lg-5 mt-4">
                <div className="col-sm-6 grids_info">
                  <i className="fas fa-bullseye" />
                  <div className="detail mt-sm-4 mt-3">
                    <h4>Our Mission</h4>
                    <p>
                      Our commitment is unwavering as we endeavor to uphold our
                      promises and deliver exceptional value-added services to
                      our clients. Our aspiration is to set new industry
                      standards in terms of quality, reliability, and
                      consistency.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 grids_info mt-sm-0 mt-4">
                  <i className="fas fa-eye" />
                  <div className="detail mt-sm-4 mt-3">
                    <h4> Our Vision</h4>
                    <p>
                      Our vision is to establish ourselves as a renowned
                      logistics company, fortified by a robust agent network and
                      a loyal clientele. We aim to enhance logistics efficiency
                      and become the premier omnichannel service provider.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-lg-0 mt-5">
              <div className="row align-items-center">
                <img
                  src="assets/images/about.jpg"
                  alt=""
                  className="img-fluid radius-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //about section */}
      <section className="w3l-content-block1 py-5">
        <div className="container py-md-5 py-4">
          <div className="row">
            <div className="col-lg-6 col-md-8 title-content py-md-5">
              <h3 className="title-style text-white">
                Navigating Challenges, Delivering Success.
              </h3>
              <p className="mt-3 text-light">
                Ready to streamline your logistics? Complete our quick 'Get a
                Quote' form, and we'll provide you with competitive shipping
                rates in no time.
              </p>
              <Link to="/Getquote" className="btn btn-style mt-5">
                Request A Quote
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* courses section */}
      <section className="w3l-courses py-5" id="courses">
        <div className="container pb-lg-5 pb-md-4 pb-2">
          <h5 className="sub-title text-center">
            Our Freight Forwarding Services
          </h5>
          <h3 className="title-style text-center">Services</h3>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Sea" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Sea Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Sea">
                      {" "}
                      <p>Sea Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Air" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Air Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Air">
                      {" "}
                      <p>Air Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Surface" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Surface Transportation.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Surface">
                      {" "}
                      <p>Surface Transportation</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="mt-5 mx-auto text-more text-center pt-lg-4">
              <Link to="/Services" className="btn btn-style">
                View All Services
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
