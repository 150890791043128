import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <img src="assets/images/logo.png" style={{ height: "75px" }} />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    About Us
                  </Link>
                </li>
                
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our Services <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-2"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/Sea">
                      Sea Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Air">
                      Air Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Surface">
                      Surface Transportation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Warehousing">
                      Warehousing & Distribution
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Multimodal">
                      Multimodal Transportation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Projects">
                      Projects & Break Bulk
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Customs">
                      Customs Clearance
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Hazardous">
                      Hazardous Cargo
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our Trading Solutions <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Healthcare
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Retail
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Construction
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Consumer Electronics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Direct to Consumer
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Marketplace & Sellers
                      </Link>
                    </li>
                    
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Careers">
                    Careers
                  </Link>
                </li>
              </ul>
             
            </div>
            {/* toggle switch for light and dark theme */}
            <div className="cont-ser-position">
            <Link to="/Getquote" className="btn btn-style ">
                    Place Quote
                  </Link>
            </div>
            {/* //toggle switch for light and dark theme */}
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
